<template>
  <custom-content title="Now" icon="emojis/hand">
    <div class="intro">
      <div>
        <p>
          Here would be ideal to write something inspiring, which reflects my
          mindset and represents my personality.
        </p>
        <p>
          But I have no idea what to write here. I still have a lot to learn,
          the journey has only just begun.
        </p>
        <p>
          Every day is a new adventure where I meet new people with whom I do
          part of this ride.
        </p>
        <p>
          However I am sure of one thing, life is short and you have to live
          every moment as the last.
        </p>
        <br />
        <p class="author">Aymeric Le Feyer</p>
      </div>
      <img
        src="@/assets/photos/selfie.png"
        alt="Photo of myself"
        class="photo"
      />
    </div>
    <div class="divider"></div>
    <div class="section-title">
      <custom-icon :square="true" icon="emojis/rocket" size="30" />
      <h2>2024 - What's up this year ?</h2>
    </div>

    <ul>
      <li>
        🎥 I've started a
        <a
          class="link"
          href="https://aymeric.lefeyer.fr/youtube"
          target="_blank"
          >YouTube channel</a
        >
        on which I share the things I love related to IT (productivity, tech,
        dev).
      </li>
      <li>
        🎙️ I'm now in charge of the meetups inside
        <a class="link" href="https://www.pit.fr" target="_blank">Pit</a>.
      </li>
      <li>🇷🇪 I visited La Réunion for the first time.</li>
      <li>
        🧑‍🏫 I attended to the
        <a href="https://devfest.gdglille.org/" target="_blank" class="link"
          >Devfest LILLE 2024</a
        >
        with <a class="link" href="https://www.pit.fr" target="_blank">Pit</a>.
        this year again.
      </li>
    </ul>

    <p class="caption">Last updated on June 2024</p>

    <div class="divider"></div>
    <div class="section-title">
      <custom-icon :square="true" icon="emojis/down" size="30" />
      <h2>Previous years</h2>
    </div>
    <h3>2023</h3>
    <ul>
      <li>
        🤝 Still with
        <a class="link" href="https://www.pit.fr" target="_blank">Pit</a>, I
        started giving technical interviews to candidates, and I'm in charge of
        my first intern.
      </li>
      <li>
        🗣️ I organized a new
        <a
          href="https://fellow.app/blog/meetings/brown-bag-meeting-what-it-is-and-how-it-benefits-your-team/"
          target="_blank"
          class="link"
          >BBL</a
        >
        within
        <a class="link" href="https://www.pit.fr" target="_blank">Pit</a> on the
        theme of
        <a class="link" href="https://www.notion.so" target="_blank">Notion</a>.
        This time, the invitations were open to external.
      </li>
      <li>
        🗣️ Thanks to the popularity of the previous talk, I was contacted by
        <a class="link" href="https://fr.ippon.tech/" target="_blank">Ippon</a>
        to make the
        <a class="link" href="https://www.notion.so" target="_blank">Notion</a>
        talk in their agency. It was the very first time I talked in front of
        ~30 unknown people. The replay is available on
        <a
          target="_blank"
          href="https://www.youtube.com/watch?v=9wrsKP8_G88&list=PLDDkw4lJz2_lRZJtfPsGPTKsvcPl2HvwV&index=1"
          >their Youtube channel</a
        >.
      </li>
      <li>🇹🇳 I visited Hammamet for the first time.</li>
      <li>
        🧑‍🏫 I attended to the
        <a href="https://devfest.gdglille.org/" target="_blank" class="link"
          >Devfest LILLE</a
        >
        with <a class="link" href="https://www.pit.fr" target="_blank">Pit</a>.
        this year again. The week after the event, I attented to the
        <a href="https://flutterconnection.io/" target="_blank" class="link"
          >Flutter Connection</a
        >, a conference based on
        <a href="https://flutter.dev/" target="_blank" class="link">Flutter</a>,
        a cross-platform mobile development framework.
      </li>
      <li>🏠 I bought my first house with my wife.</li>
      <li>
        🗣️ I organized a new
        <a
          href="https://fellow.app/blog/meetings/brown-bag-meeting-what-it-is-and-how-it-benefits-your-team/"
          target="_blank"
          class="link"
          >BBL</a
        >
        within
        <a class="link" href="https://www.pit.fr" target="_blank">Pit</a>
        on
        <a class="link" href="https://flutter.dev/" target="_blank">Flutter</a>.
      </li>
      <li>
        🧑‍💻 I get a role of CTO in
        <a class="link" href="https://www.pit.fr" target="_blank">Pit</a> with
        two others colleagues. We're making the circle of CTO's. We are in
        charge of the technical choices, and the technical strategy of the
        company.
      </li>
    </ul>
    <h3>2022</h3>
    <ul>
      <li>
        🗣️
        <a class="link" href="https://www.pit.fr" target="_blank">Pit</a> gave
        me the opportunity to present my first
        <a
          href="https://fellow.app/blog/meetings/brown-bag-meeting-what-it-is-and-how-it-benefits-your-team/"
          target="_blank"
          class="link"
          >BBL</a
        >, on the
        <a
          href="https://www.cloudamqp.com/blog/what-is-message-queuing.html"
          class="link"
          target="_blank"
          >Message Queuing</a
        >. It was exciting but so stressful because I wasn't used to speak in
        front of an audience.
      </li>
      <li>
        🧑‍💻 I discovered
        <a href="https://threejs.org/" target="_blank" class="link">Three.JS</a
        >, a 3D modelisation javascript library. I started to follow the
        <a href="https://threejs-journey.com/" target="_blank" class="link"
          >three.js journey</a
        >, an online course given by
        <a href="https://bruno-simon.com/" class="link" target="_blank"
          >Bruno Simon</a
        >.
      </li>
      <li>🇧🇪 I visited Bruxelles for the first time.</li>
      <li>🇫🇷 I visited Paris for the first time.</li>
      <li>
        🗣️ Because I liked the first
        <a
          href="https://fellow.app/blog/meetings/brown-bag-meeting-what-it-is-and-how-it-benefits-your-team/"
          target="_blank"
          class="link"
          >BBL</a
        >, I gave another one on
        <a
          href="https://github.com/features/copilot"
          target="_blank"
          class="link"
          >Github Copilot</a
        >, again with
        <a class="link" href="https://www.pit.fr" target="_blank">Pit</a>. I
        think this time it was better, but I still have a lot to learn to give a
        good talk.
      </li>
      <li>
        🧑‍🏫 I attended to the
        <a href="https://devfest.gdglille.org/" target="_blank" class="link"
          >Devfest LILLE</a
        >
        with <a class="link" href="https://www.pit.fr" target="_blank">Pit</a>.
        We had the opportunity to watch a lot of talks about the latest
        technologies, and to meet a lot of people from the community.
      </li>
      <li>
        🎓 I obtained my master's degree in computer science at
        <a href="https://www.univ-lille.fr/" target="_blank" class="link"
          >Université de Lille</a
        >. I'm so happy to have finished this long journey, and I'm proud of
        myself.
      </li>
      <li>
        ✏️ I created my first
        <a href="http://blog.aymeric.lefeyer.fr/" target="_blank" class="link"
          >blog</a
        >
        to share my knowledge and my discoveries about web and mobile
        technologies. I made it with
        <a href="https://notion.so" class="link" target="_blank">Notion</a> to
        have a nice design and to facilitate the writing and the maintenance.
      </li>
      <li>🇪🇸 I visited Mallorca for the first time.</li>
      <li>
        🎓 I followed a training on
        <a class="link" href="https://kubernetes.io/fr/" target="_blank"
          >Kubernetes</a
        >
        within
        <a class="link" href="https://www.pit.fr" target="_blank">Pit</a>.
      </li>
      <li>
        I started working at
        <a class="link" href="https://www.decathlon.fr">Decathlon</a> as a
        Mobile Software Engineer
      </li>
      <li>
        📄 I started a permanent contract at
        <a class="link" href="https://www.pit.fr" target="_blank">Pit</a> as a
        fullstack developer. I'm so happy to be part of this company, and I'm
        looking forward to working with them as long as possible.
      </li>
    </ul>
    <h3>2021</h3>
    <ul>
      <li>🇳🇱 I visited Amsterdam for the first time.</li>
      <li>
        💸 I started my journey as a
        <a
          href="https://www.societe.com/societe/monsieur-aymeric-le-feyer-901600254.html"
          >freelancer</a
        >
        to work on cool projects and to improve my skills beyond my daily
        business. I worked on mobile projects during my free time.
      </li>
      <li>
        🎓 I get my first certification in computer sciences :
        <a
          href="https://learn.microsoft.com/fr-fr/certifications/exams/az-900"
          target="_blank"
          class="link"
          >Azure Fundamentals AZ-900</a
        >.
      </li>
    </ul>
  </custom-content>
</template>

<script>
import CustomContent from "../components/Content.vue";
import CustomIcon from "../components/Icon.vue";
export default {
  components: {
    CustomContent,
    CustomIcon,
  },
  computed: {
    icon() {
      return this.$route.meta.breadcrumb[1].icon;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.intro {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  flex: 1 1 0;
  p {
    margin-right: 20px;
  }
}

li {
  margin-bottom: 20px;
  margin-left: 20px;
}

.caption {
  font-style: italic;
  color: var(--link-color);
  margin-bottom: 10px;
}

.author {
  text-align: end;
  font-weight: bold;
}

.photo {
  object-fit: cover;
  max-width: 40%;
}

@media (max-width: $breakpoint-medium) {
  .intro {
    display: block;

    p {
      margin-left: 0px;
    }
  }

  .photo {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: $breakpoint-large) {
  li {
    margin-left: 25px;
  }
}
</style>
